
/*
Put all theme based cutom jquery code here :)  Happy days!
*/

jQuery(document).ready(function() {
    (function($) {
    $( "input.gform_button.button" ).addClass( "primary large" );
    })(jQuery);

});